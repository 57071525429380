.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.pie{
  background-color: rgb(2, 2, 70);
  color: rgb(1,1,32);
  
}
.bg-citas{
  background-color: rgb(136, 136, 136);
}

input {
 
  padding-top: 10px;
  padding-bottom: 5;
  padding-left: 10px;
}

select{
  
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 10px;
  
}

.pole{
  background-color: #2A2159;
  align-items: center;
  width: 1000px;
  height: 39px;
  margin: 0px auto;
}
.pole2{
  background-color: #7E8383;
  align-items: center;
  width: 1000px;
  height: 39px;
  margin: 0px auto;
}

.text{
  font-size: 20px;
}

.menu-margin{
  margin-left: 36%;
}
.margin-logo{
  margin-left: 150px;
  
}
.margin-z{
  margin-left: 30%;
}

.containers{
  margin-left: 234px;
  margin-right: 234px;
  padding: 0;
}

.bg-white{
  background-color: white;
  border: none;
}

.text-MARGIN{
  margin-right:-58%;
  font-size: 10px;
}

.font1{
  font-size: 26px;
  font-family: Arial;
}

.bg-custom{
  background-color: #d9d9d9;
  color: black;
  border: 1px solid black;
}
.pies{
  font-family: Arial;
  font-size: 10px;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
